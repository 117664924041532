<style lang="scss">
.page-spiders {
    .spider-tab {
        padding: 10px 12px;
    }
    .spider-items {
        margin-top: 20px;
    }
    .spider-cards {
        .el-card__body {
            padding: 0;
        }
        .el-collapse-item__header.is-active {
            font-weight: bold;
        }
        .card {
            margin-bottom: 15px;
            padding: 10px 12px;
            line-height: 1.6;
            font-size: 13px;
            .title {
                word-break: break-all;
            }
            .name {
                display: inline-block;
                width: 65px;
                text-align: right;
            }
        }
        .success-card {
            border: 1px solid #67C23A;
            background-color: #fbfffa;
        }
        .error-card {
            border: 1px solid #F56C6C;
            background-color: #fffbfb;
        }
        .el-tabs--left.el-tabs--card .el-tabs__item {
            text-align: center;
        }
    }
}
.spider-alert {
    word-break: break-word !important;
    width: 60% !important;
    min-width: 300px !important;
    .el-message-box__message p {
        max-height: 65vh;
        overflow: auto;
    }
}
</style>

<template>
    <div class="page-spiders">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-button type="primary" size="medium" @click="getSpiders()"><i class="el-icon-refresh"></i> 刷新数据</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-alert class="mrgb10" type="error" :closable="false" center
                v-if="errorSpiderNum > 0" :title="'当前有' + errorSpiderNum + '个爬虫执行失败!'"></el-alert>
            <el-collapse class="spider-cards" v-model="activeNames">
                <el-collapse-item :title="withoutName" :name="withoutName">
                    <el-row class="mrgt15" :gutter="10">
                        <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4" v-for="item of withoutAreaDatas" :key="'without' + item.spiderName">
                            <el-card class="card" :class="item.status === 1 ? 'success-card' : 'error-card'" shadow="hover"
                                @click.native="showSpiderLogs('', item.spiderName, item.logs)">
                                <div class="fwb">{{ item.spiderName }}</div>
                                <div>最后运行时间：{{ item.lastRunTime }}</div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item :title="key" :name="key" v-for="(pitem, key) of existAreaDatas" :key="key">
                    <el-row class="mrgt15" :gutter="10">
                        <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4" v-for="item in pitem" :key="'s' + item.spiderName">
                            <el-card class="card" :class="item.status === 1 ? 'success-card' : 'error-card'" shadow="hover" 
                                @click.native="showSpiderLogs(key, item.spiderName, item.logs)">
                                <div class="fwb">{{ item.spiderName }}</div>
                                <div>最后运行时间：{{ item.lastRunTime }}</div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-collapse-item>
            </el-collapse>
        </div>
        
        <!--爬虫历史日志-->
        <spider-history :ref="refSpiderHistory" @refreshData="getSpiders()"></spider-history>
    </div>
</template>

<script>
import * as funSpider from "@/api/spider"
import SpiderHistory from "./components/spiderHistory"
export default {
    name: "PageSpiders",
    components: { SpiderHistory },
    data() {
        return {
            refSpiderHistory: "refSpidersToHistory",
            activeNames: [],
            withoutName: "无服务区",
            withoutAreaDatas: {}, // 没有服务区概念的数据
            existAreaDatas: {}, // 有服务区概念的数据
            errorSpiderNum: 0 // 失败的爬虫数量
        }
    },
    mounted() {
        this.getSpiders()
    },
    methods: {
        async getSpiders() {
            this.errorSpiderNum = 0
            this.spiderDatas = []
            this.activeNames = []
            this.withoutAreaDatas = {}
            this.existAreaDatas = {}
            window.$common.fullLoading()
            await funSpider.getAllSpider().then(res => {
                window.$common.closeFullLoading()
                // 按爬虫名称排序
                res = res.sort((a, b) => {
                    return a.spiderName.localeCompare(b.spiderName, 'zh-CN')
                })
                for (let i in res) {
                    let item = res[i]
                    item.lastRunTime = this.getFormatDate(item.lastRunTime)
                    if (!item.serviceId) {
                        // 没有服务区概念的数据
                        if (!this.withoutAreaDatas[item.spiderName]) {
                            this.withoutAreaDatas[item.spiderName] = item
                            if (item.status !== 1) {
                                this.errorSpiderNum++
                            }
                        }
                    } else {
                        // 有服务区概念的数据
                        if (!this.existAreaDatas[item.serviceName]) {
                            // 没有对应的服务区就新增
                            this.existAreaDatas[item.serviceName] = [item]
                            this.activeNames.push(item.serviceName)
                            if (item.status !== 1) {
                                this.errorSpiderNum++
                            }
                        } else {
                            // 有对应的服务区就追加
                            this.existAreaDatas[item.serviceName].push(item)
                            if (item.status !== 1) {
                                this.errorSpiderNum++
                            }
                        }
                    }
                }
                // 新增无服务区分组
                if (this.withoutAreaDatas != {}) {
                    this.activeNames.unshift(this.withoutName)
                }
                console.log(this.existAreaDatas)
            })
        },
        showSpiderLogs(areaName, spiderName, datas) {
            this.$refs[this.refSpiderHistory].open(areaName, spiderName, datas)
        }
    }
}
</script>